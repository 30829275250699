import React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import Heading from '@atoms/Heading'
import Button from '@atoms/Button'
import HeroTail from '@svg/hero-tail.svg'
import CurvedShape from '@svg/Tail.svg'

const MarchantHero = ({ head, headSecondLine, lead, button }) => {
  return (
    <section className='bg-brand-black pt-3 pb-6 md:pt-8 md:pb-12 lg:pt-16 lg:pb-16 relative mb-20'>
      <div className='max-w-screen-2xl mx-auto px-6 md:px-4'>
        <div className='flex flex-wrap items-center -mx-4'>
          <div className='w-full md:w-1/2 px-4'>
            <div className='xl:-mt-24 lg:pl-20'>
              <Heading variant='h1' color='white' classes='hero-title'>
                {head}
                {headSecondLine &&
                  <span className='lg:block'>{headSecondLine}</span>
                }
              </Heading>
              <div className='prose prose-md md:prose-xl text-white'>
                {lead}
              </div>
              <div className='max-w-sm mx-auto md:max-w-none md:mx-0'>
                <div className='mt-6 md:mt-16 md:mb-20 lg:mb-28 xl:mb-0'>
                  <div className='md:max-w-xs py-2 px-6 md:px-0'>
                    <Button type='internal' variant='primary' classes='w-full md:w-auto' isWide url='/simulator'>
                      {button}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/2 px-4'>
            <div className='max-w-sm mx-auto md:max-w-none md:mx-0 relative px-4 md:px-0 lg:pl-20 pt-2 z-10 transform translate-y-8 lg:-translate-y-10'>
              <StaticImage
                src='../../../images/marchants-hero.png'
                layout='constrained'
                width={1188}
                height={1188}
                placeholder=''
                quality={100}
                alt={head}
              />
              <HeroTail className='absolute left-0 bottom-0 z-10 hero-tail' />
            </div>
          </div>
        </div>
      </div>
      <CurvedShape className='absolute bottom-0 left-0 w-full block pointer-events-none hero-curved' />
    </section>
  )
}

MarchantHero.propTypes = {
  head: PropTypes.string,
  lead: PropTypes.string,
  button: PropTypes.string,
}

MarchantHero.defaultProps = {
  head: ``,
  lead: ``,
  button: ``,
}

export default MarchantHero
