import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

const AgileItem = ({ head, lead, icon }) => {
  return (
    <div className='loop-item text-center'>
      <div className='flex flex-col justify-center items-center'>
        <div className='loop-item-icon'>
          <GatsbyImage
            image={icon}
            layout='fullWidth'
            quality={100}
            alt={head}
          />
        </div>
        <h4 className='text-secondary text-h3/m lg:text-h4 font-normal loop-item-head'>{head}</h4>
        <div className='text-xs leading-snug text-text-80 loop-item-body'>
          <p className='px-2'>{lead}</p>
        </div>
      </div>
    </div>
  )
}

AgileItem.propTypes = {
  head: PropTypes.string,
  lead: PropTypes.string
}

AgileItem.defaultProps = {
  head: ``,
  lead: ``
}

export default AgileItem
