import React, { useEffect, useState } from 'react'
import { getImage, StaticImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Lottie from 'react-lottie-player'
import VisibilitySensor from 'react-visibility-sensor'
import ReactMarkdown from 'react-markdown'
import Heading from '@atoms/Heading'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Button from '@atoms/Button'
import SectionTail from '@svg/features-sections-tail.svg'
import SectionTail2 from '@svg/section-tail2.svg'
import SectionTailFlipped from '@svg/section-tail-flipped.svg'
import StepsTailTop from '@svg/step-tail-top.svg'
import StepsTailNextSection from '@svg/steps-tail-next-section.svg'
import AgileTailTop from '@svg/agile-tail-top.svg'
import AgileTailBottom from '@svg/agile-tail-bottom.svg'
import StepLineHorizontal from '@svg/step-line-horizontal.svg'
import StepLineVertical from '@svg/step-line-vertical.svg'
import Loop from '@svg/loop.svg'
import LoopVertical from '@svg/loop-vertical.svg'
import ArrowUp from '@svg/arrow-up.svg'
import Star from '@svg/star.svg'
import Person from '@svg/person.svg'
import SectionHeader from '@molecules/SectionHeader'
import StepItem from '@molecules/StepItem'
import AgileItem from '@molecules/AgileItem'
import NumberItem from '@molecules/NumberItem'
import QuoteSection from '@organisms/QuoteSection'
import CallToActionSection from '@organisms/CallToActionSection'
import MarchantHero from '@organisms/MarchantHero'
import MarchantFeatureSection from '@organisms/MarchantFeatureSection'
import QuoteTailTop from '@svg/quote-tail-top.svg'
import QuoteTail from '@svg/tail-quote.svg'
import Slider from 'react-slick'
import LazyLoad from 'react-lazyload'
import 'slick-carousel/slick/slick.css'

const numbersIcons = [
  <ArrowUp className='w-10 h-10 md:w-12 md:h-12 fill-current' />,
  <Star className='w-10 h-10 md:w-12 md:h-12 fill-current' />,
  <Person className='w-10 h-10 md:w-12 md:h-12 fill-current' />
]

const MarchantPage = ({ data: { strapiMarchantPage } }) => {
  const [animationMerchant01, setAnimationMerchant01] = useState()
  const [animationMerchant02, setAnimationMerchant02] = useState()
  const [animationMerchant03, setAnimationMerchant03] = useState()
  const [animationMerchant04, setAnimationMerchant04] = useState()
  const [animationInterfaceWeb, setAnimationInterfaceWeb] = useState()
  const [animationInterfaceMobile, setAnimationInterfaceMobile] = useState()

  useEffect(() => {
    import('../animations/merchant-1.json').then(setAnimationMerchant01)
    import('../animations/merchant-2.json').then(setAnimationMerchant02)
    import('../animations/merchant-3.json').then(setAnimationMerchant03)
    import('../animations/merchant-4.json').then(setAnimationMerchant04)
    import('../animations/interface-web.json').then(setAnimationInterfaceWeb)
    import('../animations/interface-mobile.json').then(setAnimationInterfaceMobile)
  }, []);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    lazyLoad: true,
    autoplay: true,
    pauseOnDotsHover: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1
  }
  return (
    <Layout headerBg='bg-brand-black' buttonLabel='For Shoppers' buttonUrl='/'>
      <Seo title='Tenko App | Merchant' />
      <MarchantHero
        head={strapiMarchantPage.heroHead}
        headSecondLine={strapiMarchantPage.heroHeadSecondLine}
        lead={strapiMarchantPage.heroLead}
        button={strapiMarchantPage.heroButton}
      />

      <MarchantFeatureSection
        index={0}
        head={strapiMarchantPage.marchantFeatureSections1[0].head}
        headSecondLine={strapiMarchantPage.marchantFeatureSections1[0].headSecondLine}
        lead={strapiMarchantPage.marchantFeatureSections1[0].lead}
        hasButton={strapiMarchantPage.marchantFeatureSections1[0].hasButton}
        buttonText={strapiMarchantPage.marchantFeatureSections1[0].buttonText}
        buttonUrl={strapiMarchantPage.marchantFeatureSections1[0].buttonUrl}
      >
        <div className='aspect-w-1 aspect-h-1'>
          <LazyLoad offset={100}>
            {(!animationMerchant01) ? (
              <div></div>
            ) : (
              <VisibilitySensor>
                {({ isVisible }) =>
                  <Lottie play={isVisible ? true : false} animationData={animationMerchant01} />
                }
              </VisibilitySensor>
            )
            }
          </LazyLoad>
        </div>
        {/* <StaticImage
          src='../images/marchant-placeholder.png'
          layout='fullWidth'
          placeholder=''
          quality={100}
          alt={strapiMarchantPage.marchantFeatureSections1[0].head}
        /> */}
      </MarchantFeatureSection>

      <MarchantFeatureSection
        index={1}
        head={strapiMarchantPage.marchantFeatureSections1[1].head}
        headSecondLine={strapiMarchantPage.marchantFeatureSections1[1].headSecondLine}
        lead={strapiMarchantPage.marchantFeatureSections1[1].lead}
        hasButton={strapiMarchantPage.marchantFeatureSections1[1].hasButton}
        buttonText={strapiMarchantPage.marchantFeatureSections1[1].buttonText}
        buttonUrl={strapiMarchantPage.marchantFeatureSections1[1].buttonUrl}
      >
        <div className='aspect-w-1 aspect-h-1'>
          <LazyLoad offset={100}>
            {(!animationMerchant02) ? (
              <div></div>
            ) : (
              <VisibilitySensor>
                {({ isVisible }) =>
                  <Lottie play={isVisible ? true : false} animationData={animationMerchant02} />
                }
              </VisibilitySensor>
            )
            }
          </LazyLoad>
        </div>
        {/* <StaticImage
          src='../images/marchant-placeholder-2.png'
          layout='fullWidth'
          placeholder=''
          quality={100}
          alt={strapiMarchantPage.marchantFeatureSections1[1].head}
        /> */}
      </MarchantFeatureSection>


      <section className=' pt-24 md:pt-48 pb-12 md:pb-20 relative bg-neutral-100 lg:bg-white'>
        <StepsTailTop className='lg:hidden absolute w-full top-0 z-10 left-1/2 transform -translate-x-1/2' />
        <div className='lg:relative max-w-screen-2xl mx-auto'>
          <SectionTail className='hidden lg:block absolute top-1/2 left-1/2 md:left-auto transform -translate-y-1/2 -translate-x-1/2 md:-translate-x-0 steps-sections-tail' />
          <SectionHeader
            head={strapiMarchantPage.marchantStepsHead}
            headSecondLine={strapiMarchantPage.marchantStepsHeadSecondLine}
            lead={strapiMarchantPage.marchantStepsLead}
            maxWidth='max-w-3xl'
            headVariant='h2'
            headClasses='mb-3'
          />

          <div className='max-w-screen-xl mx-auto px-6 md:px-4'>
            <div className='flex justify-center'>
              <div className='inline-flex flex-wrap flex-col md:flex-row justify-center relative'>
                <StepLineHorizontal className='hidden md:block absolute left-1/2 transform -translate-x-1/2 pointer-events-none step-horizontal-line' />
                <StepLineVertical className='md:hidden absolute top-0 left-1/2 transform -translate-x-1/2 ointer-events-none step-vertical-line' />
                {strapiMarchantPage.marchentSteps.map(({ id, headFirstLine, headSecondLine, lead, icon }, index) => {
                  return (
                    <StepItem
                      key={id}
                      index={index + 1}
                      headFirstLine={headFirstLine}
                      headSecondLine={headSecondLine}
                      lead={lead}
                      icon={getImage(icon?.localFile)}
                    >
                    </StepItem>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='relative pt-2 md:pt-20'>
        <StepsTailNextSection className='lg:hidden absolute w-full top-0 left-1/2 transform -translate-x-1/2' />
        <div className='max-w-6xl mx-auto px-6 md:px-4 relative text-center mb-8'>
          <Heading
            variant='h3'
          >
            {strapiMarchantPage.marchentTrySectionHead}
            <br />
            {strapiMarchantPage.marchentTrySectionHeadSecondLine}
          </Heading>
          <div className='prose max-w-none text-center'>
            <ReactMarkdown children={strapiMarchantPage.marchentTrySectionLead} />
          </div>
          <div className='mt-6'>
            <Button
              type='internal'
              variant='primary'
              isWide
              url='/simulator'>
              See it in action
            </Button>
          </div>
        </div>
        <div className='max-w-4xl mx-auto px-4'>
          <div className='hidden md:block mb-10'>
            <div className='aspect-w-16 aspect-h-10'>
              <LazyLoad offset={100}>
                {(!animationInterfaceWeb) ? (
                  <div></div>
                ) : (
                  <VisibilitySensor>
                    {({ isVisible }) =>
                      <Lottie play={isVisible ? true : false} animationData={animationInterfaceWeb} />
                    }
                  </VisibilitySensor>
                )
                }
              </LazyLoad>
            </div>
          </div>

          <div className='md:hidden'>
            <div className='aspect-w-1 aspect-h-1'>
              <LazyLoad offset={100}>
                {(!animationInterfaceMobile) ? (
                  <div></div>
                ) : (
                  <VisibilitySensor>
                    {({ isVisible }) =>
                      <Lottie play={isVisible ? true : false} animationData={animationInterfaceMobile} />
                    }
                  </VisibilitySensor>
                )
                }
              </LazyLoad>
            </div>
          </div>

        </div>
      </section>

      <section className='relative bg-neutral-100 lg:bg-white pt-16 md:pt-24'>
        <AgileTailTop className='lg:hidden absolute top-0 left-1/2 transform -translate-x-1/2 w-full pointer-events-none' />
        <div className='lg:relative max-w-screen-2xl mx-auto'>
          <SectionTailFlipped className='hidden lg:block absolute agile-sections-tail' />
          <div className='max-w-6xl mx-auto px-6 md:px-4 relative text-center mb-16'>
            <Heading variant='h3'>
              {strapiMarchantPage.agileMarketingHead}
              <br />
              {strapiMarchantPage.agileMarketingHeadSecondLine}
            </Heading>
          </div>
          <div className='max-w-screen-xl mx-auto px-6 md:px-4'>
            <div className='flex justify-center mt-12 md:mt-24'>
              <div className='flex flex-wrap flex-col md:flex-row justify-center relative'>
                <Loop className='hidden md:block absolute pointer-events-none loop-line' />
                <LoopVertical className='block md:hidden w-1/2 absolute top-12 left-1/2 transform -translate-x-1/2 pointer-events-none loop-line-vertical' />
                {strapiMarchantPage.agileItems.map(({ id, head, lead, icon }) => {
                  return <AgileItem key={id} head={head} lead={lead} icon={getImage(icon?.localFile)} />
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="relative md:pt-36">
        <AgileTailBottom className='lg:hidden absolute top-0 left-1/2 transform -translate-x-1/2 w-full pointer-events-none' />
      </div>

      <MarchantFeatureSection
        index={0}
        head={strapiMarchantPage.marchantFeatureSections2[0].head}
        headSecondLine={strapiMarchantPage.marchantFeatureSections2[0].headSecondLine}
        lead={strapiMarchantPage.marchantFeatureSections2[0].lead}
        hasButton={strapiMarchantPage.marchantFeatureSections2[0].hasButton}
        buttonText={strapiMarchantPage.marchantFeatureSections2[0].buttonText}
        buttonUrl={strapiMarchantPage.marchantFeatureSections2[0].buttonUrl}
      >
        <div className='aspect-w-1 aspect-h-1'>
          <LazyLoad offset={100}>
            {(!animationMerchant03) ? (
              <div></div>
            ) : (
              <VisibilitySensor>
                {({ isVisible }) =>
                  <Lottie play={isVisible ? true : false} animationData={animationMerchant03} />
                }
              </VisibilitySensor>
            )
            }
          </LazyLoad>
        </div>
        {/* <StaticImage
          src='../images/marchant-placeholder-3.png'
          layout='fullWidth'
          placeholder=''
          quality={100}
          alt={strapiMarchantPage.marchantFeatureSections2[0].head}
        /> */}
      </MarchantFeatureSection>

      <MarchantFeatureSection
        index={1}
        head={strapiMarchantPage.marchantFeatureSections2[1].head}
        headSecondLine={strapiMarchantPage.marchantFeatureSections2[1].headSecondLine}
        lead={strapiMarchantPage.marchantFeatureSections2[1].lead}
        hasButton={strapiMarchantPage.marchantFeatureSections2[1].hasButton}
        buttonText={strapiMarchantPage.marchantFeatureSections2[1].buttonText}
        buttonUrl={strapiMarchantPage.marchantFeatureSections2[1].buttonUrl}
      >
        <div className='aspect-w-1 aspect-h-1'>
          <LazyLoad offset={100}>
            {(!animationMerchant04) ? (
              <div></div>
            ) : (
              <VisibilitySensor>
                {({ isVisible }) =>
                  <Lottie play={isVisible ? true : false} animationData={animationMerchant04} />
                }
              </VisibilitySensor>
            )
            }
          </LazyLoad>
        </div>
        {/* <StaticImage
          src='../images/marchant-placeholder-4.png'
          layout='fullWidth'
          placeholder=''
          quality={100}
          alt={strapiMarchantPage.marchantFeatureSections2[1].head}
        /> */}
      </MarchantFeatureSection>

      <section className='pt-12 pb-0 bg-neutral-100 lg:bg-white md:pt-48 relative'>
        <SectionTail2 className='md:hidden w-full absolute top-0 left-1/2  transform -translate-x-1/2' />
        <div className='lg:relative max-w-screen-2xl mx-auto'>
          <SectionTail className='hidden lg:block absolute z-10 top-1/2 left-1/2 md:left-auto transform -translate-y-1/2 -translate-x-1/2 md:-translate-x-0 numbers-sections-tail' />
          <div className='max-w-6xl mx-auto px-6 md:px-4 relative z-20'>
            <div className='text-center mb-16 px-6 md:px-0'>
              <Heading variant='h3'>
                {strapiMarchantPage.numbersHeader}
                <span className='block md:inline'>{strapiMarchantPage.numbersHeaderSecondLine}</span>
              </Heading>
            </div>
          </div>
          <div className='max-w-6xl mx-auto px-6 md:px-4 relative z-20'>
            <div className='flex flex-wrap -mx-4 xl:-mx-12 px-6 md:px-0'>
              {strapiMarchantPage.numberItems.map(({ id, number, head, sufix, decimals, decimal, duration }, index) => {
                return (
                  <NumberItem key={id} number={number} head={head} sufix={sufix} decimals={decimals} decimal={decimal} duration={duration}>
                    {numbersIcons[index]}
                  </NumberItem>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section className='md:pt-24 relative'>
        <QuoteTailTop className='absolute top-0 z-10 left-1/2 transform -translate-x-1/2 w-full lg:hidden' />
        <div className='max-w-screen-xl mx-auto px-6 md:px-4 relative z-50'>
          <Slider {...settings}>
            {strapiMarchantPage.quotes.map(({ id, quote, name, company, image }) => {
              return (
                <div key={id}>
                  <QuoteSection
                    quote={quote}
                    name={name}
                    company={company}
                    image={getImage(image?.localFile)}
                  />
                </div>
              )
            })}
          </Slider>
          <QuoteTail className='hidden md:block text-primary-80 fill-current w-full md:width-auto absolute z-10 left-4 bottom-0 transform translate-y-1/2 quote-tail' />
        </div>
      </section>

      <CallToActionSection
        bg='bg-brand-black'
        head={strapiMarchantPage.bottomCTAHead}
        headSecondLine={strapiMarchantPage.bottomCTAHeadSecondLine}
        lead={strapiMarchantPage.bottomCTALead}
      >
        <Button
          type='internal'
          variant='primary'
          isWide
          url='/simulator'>
          See it in action
        </Button>
      </CallToActionSection>

    </Layout>
  )
}

export default MarchantPage

export const query = graphql`
  query {
    strapiMarchantPage {
      heroHead
      heroHeadSecondLine
      heroLead
      heroButton
      heroButtonUrl
      marchantFeatureSections1 {
        head
        headSecondLine
        id
        lead
        hasButton
        buttonText
        buttonUrl
      }
      marchantStepsHead
      marchantStepsHeadSecondLine
      marchantStepsLead
      marchentSteps {
        headFirstLine
        headSecondLine
        lead
        id
        icon {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
      marchentTrySectionHead
      marchentTrySectionHeadSecondLine
      marchentTrySectionLead
      agileMarketingHead
      agileMarketingHeadSecondLine
      agileItems {
        head
        id
        lead
        icon {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                layout: FULL_WIDTH,
              )
            }
          }
        }
      }
      marchantFeatureSections2 {
        id
        head
        headSecondLine
        lead
        hasButton
        buttonText
        buttonUrl
      }
      numbersHeader
      numbersHeaderSecondLine
      numberItems {
        id
        number
        head
        sufix
        decimal
        decimals
        duration
      }
      quotes {
        id
        quote
        name
        company
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                layout: CONSTRAINED,
                width: 1064,
                height: 1448
              )
            }
          }
        }
      }
      bottomCTAHead
      bottomCTAHeadSecondLine
      bottomCTALead
    }
  }
`;
