import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import Heading from '@atoms/Heading'
import ReactMarkdown from 'react-markdown'

const StepItem = ({ headFirstLine, headSecondLine, lead, icon, index }) => {
  return (
    <div className='step-item text-center mb-4 md:mb-0'>
      <div className='flex flex-col justify-center items-center'>
        <div className='step-icon'>
          <GatsbyImage
            image={icon}
            layout='fullWidth'
            quality={100}
            alt={`${headFirstLine} ${headSecondLine}`}
          />
        </div>
        <div className='relative z-10 rounded-full bg-primary text-white flex items-center justify-center text-h5 md:text-h6 lg:text-h5 font-bold step-number'>
          {index}
        </div>
        <Heading
          component='h5'
          variant='h3'
          classes='text-secondary text-h4/m md:text-h5 step-head'
        >
          {headFirstLine}
          <br />
          {headSecondLine}
        </Heading>
        <div className='text-xs leading-snug step-body'>
          <ReactMarkdown children={lead} />
        </div>
      </div>
    </div>
  )
}

StepItem.propTypes = {
  headFirstLine: PropTypes.string,
  headSecondLine: PropTypes.string,
  lead: PropTypes.string
}

StepItem.defaultProps = {
  headFirstLine: ``,
  headSecondLine: ``,
  lead: ``
}

export default StepItem
