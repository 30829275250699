import React from 'react'
import PropTypes from 'prop-types'
import VisibilitySensor from 'react-visibility-sensor'
import CountUp from 'react-countup'

const NumberItem = ({ number, head, sufix, decimals, decimal, duration, children }) => {
  const [focus, setFocus] = React.useState(false);

  const onChange = (isVisible) => {
    if (isVisible) {
      setFocus(true)
    }
  }
  return (
    <div className='w-full md:w-1/3 px-4 xl:px-12 mb-20 md:mb-0 md:mt-8 flex justify-center'>
      <div className='bg-white p-1 relative w-full max-w-xs shadow-lg number-item'>
        <div className='w-16 h-16 md:w-20 md:h-20 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-primary-20 text-primary-120 rounded-full flex justify-center items-center'>
          {children}
        </div>
        <div className='bg-grey h-full px-4 pt-12 pb-2 text-center number-item-body'>
          <h3 className='text-secondary mb-3 number-head'>
            <VisibilitySensor onChange={onChange}>
              <CountUp start={focus ? 0 : null} end={number} duration={duration} decimals={decimals} decimal={decimal} redraw={true} />
            </VisibilitySensor>
            {sufix && <span>{sufix}</span>}
            <span className='block text-xs md:text-h5 text-text mt-4 md:mt-2 font-normal'>{head}</span>
          </h3>
        </div>
      </div>
    </div >
  )
}

NumberItem.propTypes = {
  number: PropTypes.string,
  head: PropTypes.string,
  sufix: PropTypes.string,
  decimals: PropTypes.string,
  decimal: PropTypes.string,
  duration: PropTypes.string
}

NumberItem.defaultProps = {
  number: ``,
  head: ``,
  sufix: ``,
  decimals: ``,
  decimal: ``,
  duration: ``
}

export default NumberItem
