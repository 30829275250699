import React from 'react'
import PropTypes from 'prop-types'
import Heading from '@atoms/Heading'
import Button from '@atoms/Button'

const MarchantFeatureSection = ({ head, headSecondLine, lead, hasButton, buttonText, buttonUrl, index, children }) => {
  return (
    <section className='py-4 relative'>
      <div className='max-w-screen-2xl mx-auto px-6 md:px-4'>
        <div className={`flex flex-wrap items-center -mx-4 ${index % 2 === 0 ? 'md:flex-row-reverse' : ''}`}>
          <div className='w-full md:w-1/2 px-4'>
            <div className='lg:pl-24 lg:pr-12'>
              <Heading component='h3' variant='h2' classes='mb-4'>
                {head}
                {headSecondLine &&
                  <span className='block'>{headSecondLine}</span>
                }
              </Heading>
            </div>
            <div>
              <div className='lg:px-24'>
                <div className='prose md:prose-lg'>
                  {lead}
                </div>
                {hasButton &&
                  <div className='mt-6 mb-6 md:mb-0'>
                    <Button type='internal' variant='primaryInverted' isWide url={buttonUrl}>
                      {buttonText}
                    </Button>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/2 px-4'>
            <div className='p-4 md:p-0 mt-3 mb-4 md:mb-8 lg:mt-0 lg:mb-0'>
              <div className='w-full m-0 max-w-md mx-auto md:max-w-none lg:px-10'>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

MarchantFeatureSection.propTypes = {
  head: PropTypes.string,
  headSecondLine: PropTypes.string,
  lead: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string
}

MarchantFeatureSection.defaultProps = {
  head: ``,
  headSecondLine: ``,
  lead: ``,
  buttonText: ``,
  buttonUrl: ``
}

export default MarchantFeatureSection
